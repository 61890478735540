import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

const Menu = () => {

  const { i18n, t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  const [isOpen, setIsOpen] = useState(false);


  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const HandleScroll = () => {
    if (window.scrollY >= 60) {
      document.getElementById("Navbar")?.classList.add("darkHeader");
    } else document.getElementById("Navbar")?.classList.remove("darkHeader");
  };

  window.addEventListener("scroll", HandleScroll);
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
  return (
    <Navbar
      id="Navbar"
      className="navbar navbar-expand-lg navbar-light theme-nav fixed-top"
    >
      <div id="navbar-main" className="container">
        <NavbarBrand
          className="navbar-brand"
          href={`${process.env.PUBLIC_URL}/`}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="logo" />
        </NavbarBrand>

      
        <div id="lag-button" className="">
          {i18n.language === "en" ? (
            <button onClick={() => changeLanguage("de")} className="btn btn-link">DE</button>
          ) : (
            <button onClick={() => changeLanguage("en")} className="btn btn-link">EN</button>
          )}
        </div>

        <NavbarToggler className="navbar-toggler" onClick={toggle} />
        <Collapse
          id="navbarSupportedContent"
          className="default-nav"
          isOpen={isOpen}
          navbar
        >
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink className="nav-link" href={`${process.env.PUBLIC_URL}/`} onClick={toggle}>{t("home")}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" href={`${process.env.PUBLIC_URL}/prototype`} onClick={toggle}>{t("prototype1")}</NavLink>
            </NavItem>
          </Nav>

        </Collapse>
      </div>
    </Navbar>
  );
};

export default Menu;
