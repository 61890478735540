import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tippy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "react-tippy/dist/tippy.css";
import "./firmenbewertungrechner.css"; // Assuming you're adding CSS here

const BranchenOptions = [
  { label: "Automobilindustrie - 9.9", value: 9.9 },
  { label: "Gesundheitswesen & Pharma - 18.8", value: 18.8 },
  { label: "Handel & Konsumgüter - 15.0", value: 15.0 },
  { label: "Immobilien - 25.3", value: 25.3 },
  { label: "Industrielle Produktion - 17.1", value: 17.1 },
  { label: "Medien - 15.0", value: 15.0 },
  { label: "Software Industrie - 28.5", value: 28.5 },
  { label: "Technologie - 21.0", value: 21.0 },
  { label: "Telekommunikation - 15.6", value: 15.6 },
  { label: "Transport & Logistik - 16.8", value: 16.8 },
  { label: "Versorgungsbetriebe - 17.9", value: 17.9 },
  { label: "Werkstoffindustrie - 14.8", value: 14.8 },
];

const FirmenbewertungRechner = () => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState({
    case_for_investment_3: t("firmenbewertungrechner.start"),
    zeitraum_3: 3,
    umsatz_n_x_3: 5000000,
    net_marge_3: 20,
    multiple_all: 15.0,
    diskontierungssatz_3: 70,
    cagr_3: 100,

    case_for_investment_5: t("firmenbewertungrechner.example_5"),
    zeitraum_5: 5,
    net_marge_5: 25,
    diskontierungssatz_5: 30,
    cagr_5: 50,

    case_for_investment_10: t("firmenbewertungrechner.example_10"),
    zeitraum_10: 10,
    net_marge_10: 30,
    diskontierungssatz_10: 20,
    cagr_10: 25,
  });

  const [highlightInput, setHighlightInput] = useState(true);
  const [calculated, setCalculated] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const calculateResults = () => {
    const {
      zeitraum_3,
      zeitraum_5,
      zeitraum_10,
      umsatz_n_x_3,
      cagr_5,
      cagr_10,
      multiple_all,
      net_marge_3,
      net_marge_5,
      net_marge_10,
      diskontierungssatz_3,
      diskontierungssatz_5,
      diskontierungssatz_10,
    } = inputs;

    const umsatz_n_x_5 = (
      umsatz_n_x_3 *
      Math.pow(1 + cagr_5 / 100, zeitraum_5 - zeitraum_3)
    ).toFixed(2);
    const umsatz_n_x_10 = (
      umsatz_n_x_5 *
      Math.pow(1 + cagr_10 / 100, zeitraum_10 - zeitraum_5)
    ).toFixed(2);

    const ebit_n_x_3 = (umsatz_n_x_3 * (net_marge_3 / 100)).toFixed(2);
    const ebit_n_x_5 = (umsatz_n_x_5 * (net_marge_5 / 100)).toFixed(2);
    const ebit_n_x_10 = (umsatz_n_x_10 * (net_marge_10 / 100)).toFixed(2);

    const value_n_x_3 = (ebit_n_x_3 * multiple_all).toFixed(2);
    const value_n_x_5 = (ebit_n_x_5 * multiple_all).toFixed(2);
    const value_n_x_10 = (ebit_n_x_10 * multiple_all).toFixed(2);

    const value_finanzierungsjahr_3 = (
      value_n_x_3 / Math.pow(1 + diskontierungssatz_3 / 100, zeitraum_3)
    ).toFixed(2);
    const value_finanzierungsjahr_5 = (
      value_n_x_5 /
      Math.pow(1 + diskontierungssatz_5 / 100, zeitraum_5 - zeitraum_3)
    ).toFixed(2);
    const value_finanzierungsjahr_10 = (
      value_n_x_10 /
      Math.pow(1 + diskontierungssatz_10 / 100, zeitraum_10 - zeitraum_5)
    ).toFixed(2);

    return {
      umsatz_n_x_5,
      umsatz_n_x_10,
      ebit_n_x_3,
      ebit_n_x_5,
      ebit_n_x_10,
      value_n_x_3,
      value_n_x_5,
      value_n_x_10,
      value_finanzierungsjahr_3,
      value_finanzierungsjahr_5,
      value_finanzierungsjahr_10,
    };
  };

  const handleFocus = () => {
    setHighlightInput(true);
    setCalculated(false);
  };

  const handleBlur = () => {
    setHighlightInput(false);
    setCalculated(true);
  };

  const results = calculateResults();

  const renderTooltip = (content) => (
    <Tooltip title={content} position="top" trigger="click" arrow={true} className="info-icon-block">
      <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
    </Tooltip>
  );

  return (
    <div className="rechner container mt-5 mb-5">
      <h2>{t("firmenbewertungrechner.title")}</h2>
      <table
        className={`table table-bordered table-responsive text-center ${
          highlightInput ? "highlight-table" : ""
        }`}
      >
        <thead>
          <tr>
            <th>
              {t("firmenbewertungrechner.variable")} {renderTooltip(t("firmenbewertungrechner.variable_tooltip"))}
            </th>
            <th>
              {t("firmenbewertungrechner.financing_year_0")}{" "}
              {renderTooltip(t("firmenbewertungrechner.financing_year_tooltip_0"))}
            </th>
            <th>
              {t("firmenbewertungrechner.financing_year_3")}{" "}
              {renderTooltip(t("firmenbewertungrechner.financing_year_tooltip_3"))}
            </th>
            <th>
              {t("firmenbewertungrechner.financing_year_5")}{" "}
              {renderTooltip(t("firmenbewertungrechner.financing_year_tooltip_5"))}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {t("firmenbewertungrechner.case_for_investments")}
              {renderTooltip(t("firmenbewertungrechner.case_for_investments_tooltip"))}
            </td>
            <td>{t("firmenbewertungrechner.start")}</td>
            <td>
              <input
                type="text"
                name="case_for_investment_5"
                value={t("firmenbewertungrechner.example_5")}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title=""
              />
            </td>
            <td>
              <input
                type="text"
                name="case_for_investment_10"
                value={t("firmenbewertungrechner.example_10")}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title=""
              />
            </td>
          </tr>
          <tr>
            <td>
              {t("firmenbewertungrechner.time_period")}
              {renderTooltip(t("firmenbewertungrechner.time_period_tooltip"))}
            </td>
            <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
              <div className="input-wrapper">
              <input
                type="number"
                name="zeitraum_3"
                value={inputs.zeitraum_3}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title=""
              />
              {renderTooltip(t("firmenbewertungrechner.time_period_tooltip_0"))}
              </div>
            </td>
            <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
              <div className="input-wrapper">
              <input
                type="number"
                name="zeitraum_5"
                value={inputs.zeitraum_5}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title=""
              />
              {renderTooltip(t("firmenbewertungrechner.time_period_tooltip_3"))}
              </div>
            </td>
            <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
              <div className="input-wrapper">
              <input
                type="number"
                name="zeitraum_10"
                value={inputs.zeitraum_10}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title=""
              />
              {renderTooltip(t("firmenbewertungrechner.time_period_tooltip_5"))}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {t("firmenbewertungrechner.revenue_n_x")} {renderTooltip(t("firmenbewertungrechner.revenue_tooltip"))}
            </td>
            <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
              <div className="input-wrapper">
              <input
                type="number"
                name="umsatz_n_x_3"
                value={inputs.umsatz_n_x_3}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title=""
              />
              {renderTooltip(t("firmenbewertungrechner.revenue_tooltip_3"))}
              </div>
            </td>
            <td className={calculated ? "result-highlight" : ""}>
              {results.umsatz_n_x_5} €
              &nbsp;&nbsp;{renderTooltip(t("firmenbewertungrechner.revenue_tooltip_5"))}
            </td>
            <td className={calculated ? "result-highlight" : ""}>
              {results.umsatz_n_x_10} €
              &nbsp;&nbsp;{renderTooltip(t("firmenbewertungrechner.revenue_tooltip_10"))}
            </td>
          </tr>
          <tr>
            <td>
              {t("firmenbewertungrechner.net_margin")}
              {renderTooltip(t("firmenbewertungrechner.net_margin_tooltip"))}
            </td>
            <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
              <div className="input-wrapper">
              <input
                type="text"
                name="net_marge_3"
                value={inputs.net_marge_3}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title=""
              />
              {renderTooltip(t("firmenbewertungrechner.net_margin_tooltip_3"))}
              </div>
            </td>
            <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
              <div className="input-wrapper">
                <input
                  type="text"
                  name="net_marge_5"
                  value={inputs.net_marge_5}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  className={`form-control`}
                  title=""
                />
                {renderTooltip(t("firmenbewertungrechner.net_margin_tooltip_5"))}
              </div>
            </td>
            <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
              <div className="input-wrapper">
              <input
                type="text"
                name="net_marge_10"
                value={inputs.net_marge_10}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title=""
              />
              {renderTooltip(t("firmenbewertungrechner.net_margin_tooltip_10"))}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {t("firmenbewertungrechner.ebit_n_x")} {renderTooltip(t("firmenbewertungrechner.ebit_tooltip"))}
            </td>
            <td className={calculated ? "result-highlight" : ""}>
              {results.ebit_n_x_3} €
            </td>
            <td className={calculated ? "result-highlight" : ""}>
              {results.ebit_n_x_5} €
            </td>
            <td className={calculated ? "result-highlight" : ""}>
              {results.ebit_n_x_10} €
            </td>
          </tr>
          <tr>
            <td>
              {t("firmenbewertungrechner.multiple")} {renderTooltip(t("firmenbewertungrechner.multiple_tooltip"))}
            </td>
            <td colSpan="3" className={`${calculated ? "input-highlight" : ""}`}>
              <select
                name="multiple_all"
                value={inputs.multiple_all}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title="Select the multiple"
              >
                {BranchenOptions.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td>
              {t("firmenbewertungrechner.value_n_x")} {renderTooltip(t("firmenbewertungrechner.value_tooltip"))}
            </td>
            <td className={calculated ? "result-highlight" : ""}>
              {results.value_n_x_3} €
              &nbsp;&nbsp;{renderTooltip(t("firmenbewertungrechner.value_tooltip_3"))}
            </td>
            <td className={calculated ? "result-highlight" : ""}>
              {results.value_n_x_5} €
              &nbsp;&nbsp;{renderTooltip(t("firmenbewertungrechner.value_tooltip_5"))}
            </td>
            <td className={calculated ? "result-highlight" : ""}>
              {results.value_n_x_10} €
              &nbsp;&nbsp;{renderTooltip(t("firmenbewertungrechner.value_tooltip_10"))}
            </td>
          </tr>
          <tr>
            <td>
              {t("firmenbewertungrechner.discount_rate")} {renderTooltip(t("firmenbewertungrechner.discount_rate_tooltip"))}
            </td>
            <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
              <div className="input-wrapper">
              <input
                type="text"
                name="diskontierungssatz_3"
                value={inputs.diskontierungssatz_3}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title=""
              />
              {renderTooltip(t("firmenbewertungrechner.discount_rate_tooltip_high"))}
              </div>
            </td>
            <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
              <div className="input-wrapper">
              <input
                type="text"
                name="diskontierungssatz_5"
                value={inputs.diskontierungssatz_5}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title=""
              />
              {renderTooltip(t("firmenbewertungrechner.discount_rate_tooltip_medium"))}
              </div>
            </td>
            <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
              <div className="input-wrapper">
              <input
                type="text"
                name="diskontierungssatz_10"
                value={inputs.diskontierungssatz_10}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`form-control`}
                title=""
              />
              {renderTooltip(t("firmenbewertungrechner.discount_rate_tooltip_low"))}
              </div>
            </td>
          </tr>
          <tr>
            
            
            
            <td>
              {t("firmenbewertungrechner.value_financing_year")} {renderTooltip(t("firmenbewertungrechner.value_financing_year_tooltip"))}
            </td>
            <td className={calculated ? "result-highlight" : ""}>
              {results.value_finanzierungsjahr_3} €
            </td>
            <td className={calculated ? "result-highlight" : ""}>
              {results.value_finanzierungsjahr_5} €
            </td>
            <td className={calculated ? "result-highlight" : ""}>
                {results.value_finanzierungsjahr_10} €
              </td>
          </tr>
            <tr>
              <td>
                {t("firmenbewertungrechner.cagr")} {renderTooltip(t("firmenbewertungrechner.cagr_tooltip"))}
              </td>
              <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
                <input
                  type="text"
                  name="cagr_3"
                  value={inputs.cagr_3}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  className={`form-control`}
                  title={t("firmenbewertungrechner.cagr")}
                />
              </td>
              <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
                <input
                  type="text"
                  name="cagr_5"
                  value={inputs.cagr_5}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  className={`form-control`}
                  title={t("firmenbewertungrechner.cagr")}
                />
              </td>
              <td className={`disply-together ${calculated ? "input-highlight" : ""}`}>
                <input
                  type="text"
                  name="cagr_10"
                  value={inputs.cagr_10}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  className={`form-control`}
                  title={t("firmenbewertungrechner.cagr")}
                />
              </td>
            </tr>



            </tbody>
            </table>

            <p>{t("firmenbewertungrechner.disclaimer")}</p>
            </div>
            );
            };

            export default FirmenbewertungRechner;

