import React, { useEffect, useState, useRef } from "react";
import Menu from "../components/Navbar";
import emailjs from 'emailjs-com';
import { useTranslation } from "react-i18next";

const ProtoType = () => {
  const { t } = useTranslation();
  const [showPwd, setShowPwd] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showAccessForm, setShowAccessForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);
  const form = useRef();

  // Pre-set credentials
  const presetUsername = "sharknex";
  const presetPassword = "Shy06i2xrNex";

  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;

    if (username === presetUsername && password === presetPassword) {
      setIsLoggedIn(true);
    } else {
      alert(t("prototype.login_error"));
    }
  };

  const handleAccessRequest = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.sendForm(
      'service_9m7dwcp',
      'template_y06i2xr',
      form.current,
      'pOl99N78xnYKJMSqL'
    ).then((result) => {
      setLoading(false);
      setSuccess(true);
    }, (error) => {
      setLoading(false);
      alert(t("prototype.access_error"));
    });
  };

  return (
    <div>
      <Menu homePage="home-one" />
      <section
        className="authentication-form"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/aut-bg.jpg)`,
          width: "100vw",
          height: "100vh",
          margin: 0,
          padding: 0,
          overflow: "hidden",
          top: "95px",
          position: "relative"
        }}
      >
        {!isLoggedIn && !showAccessForm && (
          <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="card">
              <h2 className="title text-center text-mediumsize1">
                <span>{t("prototype.login_title")}</span>
              </h2>
              <p className="text-center">
                {t("prototype.login_description")}
              </p>
              <form className="theme-form" onSubmit={handleLogin}>
                <div className="form-group">
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    placeholder={t("prototype.username_placeholder")}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    name="password"
                    type={showPwd ? "text" : "password"}
                    className="form-control"
                    placeholder={t("prototype.password_placeholder")}
                    required
                  />
                  <div
                    className="show-hide"
                    onClick={() => setShowPwd(!showPwd)}
                  >
                    <span className={!showPwd ? "show" : ""}></span>
                  </div>
                </div>
                <div className="form-button text-center">
                  <button type="submit" className="btn btn-custom btn-lg theme-color">
                    {t("prototype.login_button")}
                  </button>
                </div>
                <div className="or-saparator">
                  <span>{t("prototype.or_text")}</span>
                </div>
                <div className="form-button text-center social-btns">
                  <h6>{t("prototype.access_prompt")}</h6>
                  <button
                    type="button"
                    className="btn btn-custom"
                    onClick={() => setShowAccessForm(true)}
                  >
                    {t("prototype.access_request_button")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {isLoggedIn && (
          <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {iframeLoading && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">{t("prototype.loading_text")}</span>
                </div>
                <p>{t("prototype.loading_prototype")}</p>
              </div>
            )}

            <div
              style={{
                position: "absolute",
                top: "5%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: iframeLoading ? 1000 : 1000,
                textAlign: "center",
                color: "white",
                padding: "5px 5px 5px 5px",
                borderRadius: "2px"
              }}
            >
              <div className="title-prototype">{t("prototype.coming_soon")}</div>
            </div>

            <iframe
              className="responsive-iframe"
              style={{
                border: "none",
                width: "100%",
                height: "100%",
                display: iframeLoading ? "none" : "block"
              }}
              src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FWfoyqv6g5d8wpKsZkhP8dD%2FShark-nex-16-08-2024_08_50am%3Fpage-id%3D0%253A1%26node-id%3D1-110%26viewport%3D109%252C296%252C0.11%26t%3Dq6wVDumx86Nv2KB1-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26starting-point-node-id%3D1%253A110"
              allowFullScreen
              onLoad={() => setIframeLoading(false)}
            ></iframe>
          </div>
        )}

        {!isLoggedIn && showAccessForm && (
          <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="card">
              <h2 className="title text-center text-mediumsize">
                <span>{t("prototype.access_request_title")}</span>
              </h2>
              <form ref={form} className="theme-form" onSubmit={handleAccessRequest}>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder={t("prototype.name_placeholder")}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder={t("prototype.email_placeholder")}
                    required
                  />
                </div>
                <div className="form-button text-center">
                  <button type="submit" className="btn btn-custom btn-lg theme-color">
                    {loading ? t("prototype.sending") : t("prototype.send")}
                  </button>
                </div>
                {success && (
                  <div className="text-center" style={{ marginTop: "10px" }}>
                    <span className="text-success">{t("prototype.access_success")}</span>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default ProtoType;
