import "./App.css";
import BlogDetails from "./blog/BlogDetails";
import BlogLeftSide from "./blog/BlogLeftSide";
import BlogLeftSidebar from "./blog/BlogLeftSidebar";
import BlogList from "./blog/BlogList";
import BlogRightSide from "./blog/BlogRightSide";
import BlogRightSidebar from "./blog/BlogRightSidebar";
import DemoApp from "./demo-page/index";
import PageNotFound from "./Pages/404";
import ComingSoon from "./Pages/ComingSoon";
import Download from "./Pages/Download";
import FAQ from "./Pages/FAQ";
import ForgetPwd from "./Pages/ForgetPwd";
import Review from "./Pages/Review";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";

import ProtoType from "./Pages/ProtoType";


import ThankYou from "./Pages/ThankYou";
import React,{ useEffect} from "react";
import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";

import LandingPage from "./LandingPage";
import './i18n'; // Import the i18n configuration


import { Route, Switch } from "react-router-dom";
import ColorPicker from "./Services/ColorPicker";

function App() {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
 localStorage.setItem("color","color-1");
  return (
    <div className="App">
      {/* <ColorPicker /> */}
   
      <Switch
      >
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={LandingPage} />
        <Route path={`${process.env.PUBLIC_URL}/404`} component={PageNotFound}/>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/prototype`}
          component={ProtoType}
        />
        <Route component={PageNotFound} />
      </Switch>
      
    </div>
  );
}

export default App;
