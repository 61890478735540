import React, { useEffect, useState, useRef } from "react";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Contact.png)`
  });

  const color = localStorage.getItem("color");

  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Contact.png)`
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/contact.png)`
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/contact.png)`
      });
  }, [color]);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.sendForm(
      'service_9m7dwcp',
      'template_g83m74m',
      form.current,
      'pOl99N78xnYKJMSqL'
    ).then((result) => {
      setLoading(false);
      toast.success(t('contact.success_message'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      form.current.reset();
    }, (error) => {
      setLoading(false);
      toast.error(t('contact.error_message'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  return (
    <section id="contact" className="contact" style={bgImg}>
      <div className="contact-decor">
        <div className="contact-circle1">
          <img src="assets/images/main-banner12.png" alt="" />
        </div>
        <div className="contact-circle2">
          <img src="assets/images/main-banner1.png" alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8 col-sm-12">
            <div className="contact-us">
              <div className="w-100">
                <h2 className="title">
                  <span>{t('contact.title_highlight')}</span>{t('contact.title')}
                </h2>
                <p>{t('contact.description')}</p>
                <form ref={form} onSubmit={sendEmail} className="theme-form">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <label htmlFor="name">{t('contact.name_label')}</label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder={t('contact.name_placeholder')}
                          required="required"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <label htmlFor="rating">{t('contact.rating_label')}</label>
                        <input
                          type="number"
                          name="rating"
                          className="form-control"
                          placeholder={t('contact.rating_placeholder')}
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">{t('contact.email_label')}</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder={t('contact.email_placeholder')}
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="feedback">{t('contact.feedback_label')}</label>
                    <textarea
                      name="feedback"
                      className="form-control"
                      rows="4"
                      placeholder={t('contact.feedback_placeholder')}
                      required="required"
                    ></textarea>
                  </div>
                  <div className="form-button">
                    <button
                      type="submit"
                      className="btn btn-custom theme-color"
                      disabled={loading}
                    >
                      {loading ? t('contact.sending') : t('contact.send')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-medium-none-lg">
            <div className="contact-right">
              <img
                src="assets/images/Contact-info.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Contact;
