// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, addDoc } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBvF6XK-73XE4WEIdCFsiHBSqV3uiScQQc",
  authDomain: "sharknex-cb355.firebaseapp.com",
  projectId: "sharknex-cb355",
  storageBucket: "sharknex-cb355.appspot.com",
  messagingSenderId: "550531917130",
  appId: "1:550531917130:web:646d868ac9b8f4334f4db3",
  measurementId: "G-EWVK5JTMDR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
export const db = getFirestore(app);
