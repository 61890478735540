import React, { useEffect, useState } from "react";
import { db } from "../Services/FirebaseApi"; // Assuming Firebase is initialized in this file
import { collection, getDocs, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

const Subscribe = () => {

  const { t } = useTranslation();
  const [mailCount, setMailCount] = useState(0);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch the current count of emails when the component mounts
  useEffect(() => {
    const fetchEmailCount = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "email-list"));
        setMailCount(querySnapshot.size + 300); // Set the count to the number of documents
      } catch (error) {
        console.error("Error fetching email list: ", error);
      }
    };

    fetchEmailCount();
  }, []);

  // Handle form submission
  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Add the email to the Firestore collection
      await addDoc(collection(db, "email-list"), {
        email: email,
        timestamp: new Date(), // You can store the timestamp of when the email was added
      });

      // Update the count, clear the input field, and stop loading
      setMailCount(mailCount + 1);
      setEmail("");
      setLoading(false);

      // Set a timeout before showing the success notification
      setTimeout(() => {
        toast.success('Erfolgreich angemeldet! Vielen Dank für die Anmeldung. Wir werden dich informieren, sobald die App live ist.', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }, 4000); // 2000 milliseconds = 2 seconds delay

    } catch (error) {
      console.error("Error adding email: ", error);
      setLoading(false);
    }
  };

  return (
    <div className="" style={{ position: 'relative', zIndex: 10 }}> {/* Ensure the form is on top */}
      <p>
      {t("subscribeText")}
      </p>
      <p>
        <span className="mail-count">{mailCount} +</span> {t("subscribeText1")}
      </p>
      <form
        onSubmit={handleSubscribe}
        className="footer-form needs-validation"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        style={{ position: 'relative', zIndex: 20 }} //{/* Ensure the form is clickable */}
      >
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            placeholder={t("subscribeText2")}
            name="EMAIL"
            id="mce-EMAIL"
            required="required"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading} // Disable input while loading
            style={{ position: 'relative', zIndex: 30 }} //{/* Ensure the input is clickable */}
          />
        </div>
        <div className="form-button">
          <button
            type="submit"
            className="btn btn-custom theme-color"
            id="mc-submit"
            disabled={loading} // Disable button while loading
            style={{ cursor: loading ? 'not-allowed' : 'pointer', position: 'relative', zIndex: 30 }} //{/* Ensure the button is clickable */}
          >
            {loading ? t("subscribeText3")+'...' : t("subscribeText3")}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Subscribe;
